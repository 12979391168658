<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-12 m-12 c-12" style="justify-content: left !important;">
                    <h4 class="page-list-title"><i class="fa-regular fa-circle-question"></i> <span style="padding-left: 10px;">Hướng dẫn sử dụng</span></h4>
                </div>
            </div>
            <div class="page-list-body">
                <div class="helper-container">
                    <div class="helper-sidebar">
                        <div v-for="(item, index) in categories" :key="'cat-' + index" class="item">
                            <div style="font-weight: bold; padding: 3px 10px;">{{ item.categoryName }}</div>
                            <div v-for="(sub, i) in item.children" :key="'cat-children-' + i">
                                <div style="padding: 3px 20px;">
                                    {{ sub.categoryName }}
                                </div>
                                <div v-for="(item, idx) in items.filter(x => x.categoryId == sub.id)" :key="'item-' + idx">
                                    <div style="padding: 3px 35px;">
                                        <a :class="{'active': id == item.id}" @click="getById(item.id)">{{ item.title }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="helper-content">
                        <div v-if="loadding" class="loadding">Đang tải dữ liệu...</div>
                        <template v-else>
                            <div class="title">{{ entity.title }}</div>
                            <div class="content" v-html="entity.content"></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import helperService from '../../../api/helperService';
    import categoryHelperService from '../../../api/categoryHelperService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        metaInfo: {
            title: 'Danh sách hướng dẫn'
        },
        data() {
            return {
               loadding: false,
               data: [],
               id: 0,
               categories: [],
               items: [],
               entity: { title: '' }
            }
        },
        created(){
            this.getCategories();
            this.getData();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getCategories(){
                this.loadding = true;
                categoryHelperService.getAll(-1).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getData(){
                helperService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.items = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getById(id){
                this.id = id;
                this.loadding = true;
                helperService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
   
        },
        watch: {
        }
    }

</script>

<style scoped>
    .loadding {
        color: red;
        text-align: center;
        padding-top: 20px;
    }
    .active {
        color: var(--color-primary) !important;
    }
    .content {
        height: calc(100vh - 205px);
        width: 100%;
        overflow: auto;
        padding: 10px 0 20px 0;
    }
    .title {
        font-weight: bold;
        font-size: 16px;
        border-bottom: 1px solid #ccc;
        height: 30px;
    }
    .helper-container {
        display: flex;
    }
    .helper-sidebar {
        min-height: calc(100vh - 165px);
        height: calc(100vh - 165px);
        width: 300px;
        overflow: auto;
    }
    .helper-content {
        min-height: calc(100vh - 165px);
        max-height: calc(100vh - 165px);
        width: calc(100vw - 300px);
        padding: 10px 20px 40px 20px;
        position: relative;
    }
</style>
